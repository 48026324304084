import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import { SUCCESS, FAILED, INITIONAL_PAYMENT } from '@/constants/entityStatuses';
import { canAbility, checkAuth, startPageRedirect, loadProfile, checkNonAuth } from './middleware';
import invoices from './invoices.route';
import dashboard from './dashboard.route';
import projects from './projects.route';
import assets from './assets.route';
import clients from './clients.route';
import employees from './employees.route';
import announcements from './announcements.route';
import contractors from './contractors.route';
import contracts from './contracts.route';
import tasks from './tasks.route';
import services from './services.route';
import requests from './requests.route';
import calendar from './calendar.route';

export default [
  {
    path: '/auth',
    name: 'AuthLayout',
    component: () => import('@/layouts/Auth.vue'),
    redirect: { name: routes.LOGIN },
    meta: {
      middleware: [checkNonAuth],
    },
    children: [
      {
        path: 'login',
        name: routes.LOGIN,
        component: () => import('@/views/Login.vue'),
      },
    ],
  },
  {
    path: '/password/reset',
    name: routes.RESET_PASSWORD,
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      middleware: [checkNonAuth],
    },
  },
  {
    path: '/payment',
    name: routes.PAYMENT_PARENT,
    component: () => import('@/layouts/EmptyPage.vue'),
    redirect: { name: routes.PAYMENT_INITIONAL_PAYMENT },
    children: [
      {
        path: '/payment/initional_payment',
        name: routes.PAYMENT_INITIONAL_PAYMENT,
        component: () => import('@/views/Payment.vue'),
        props: { status: INITIONAL_PAYMENT },
      },
      {
        path: '/payment/payment_success',
        name: routes.PAYMENT_SUCCESS,
        component: () => import('@/views/Payment.vue'),
        props: { status: SUCCESS },
      },
      {
        path: '/payment/payment_failed',
        name: routes.PAYMENT_FAILED,
        component: () => import('@/views/Payment.vue'),
        props: { status: FAILED },
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/Main.vue'),
    meta: {
      middleware: [checkAuth, loadProfile],
    },
    props: route => ({ routeName: route.name, fullPath: route.fullPath }),
    children: [
      {
        path: '',
        name: routes.MAIN,
        meta: {
          middleware: [startPageRedirect],
        },
      },
      {
        path: 'cards',
        name: routes.CARDS,
        component: () => import('@/views/Cards.vue'),
        meta: {
          middleware: [canAbility([actions.READ], subjects.CARDS)],
        },
      },
      {
        path: 'profile',
        name: routes.PROFILE,
        component: () => import('@/views/Profile.vue'),
        meta: {
          middleware: [canAbility([actions.READ], subjects.PROFILE)],
        },
      },
      {
        path: 'chat',
        name: routes.CHAT,
        component: () => import('@/views/Chat.vue'),
        meta: {
          middleware: [canAbility([actions.READ], subjects.CHAT)],
        },
        props: route => ({ selectedChatId: +route.query.id }),
      },
      dashboard,
      invoices,
      projects,
      assets,
      clients,
      employees,
      contractors,
      announcements,
      contracts,
      tasks,
      services,
      requests,
      calendar,
      {
        path: '/access-denied',
        name: routes.ACCESS_DENIED,
        component: () => import('@/views/AccessError.vue'),
      },
      {
        path: '*',
        name: 'error',
        component: () => import('@/components/Error.vue'),
        props: {
          code: 404,
        },
      },
    ],
  },
];
