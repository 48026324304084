export const ACTIVE = 'active';
export const UNCONFIRMED = 'unconfirmed';
export const ARCHIVED = 'archived';
export const SHOW_ROLES = 'roles';
export const SENT = 'sent';
export const SCHEDULED = 'scheduled';
export const DRAFTS = 'draft';
export const PAID = 'paid';
export const RECURRENCE = 'recurrence';
export const UNPAID = 'unpaid';
export const PENDING = 'pending';
export const ALL = 'all';
export const OWNERS = 'owners';
export const TENANTS = 'tenants';
export const SHORT_TENANTS = 'shortTenants';
export const TEMPLATES = 'templates';
export const AGREEMENTS = 'agreements';
export const CONTRACTS = 'contarcts';
export const SUCCESS = 'success';
export const FAILED = 'failed';
export const INITIONAL_PAYMENT = 'initional_payment';
